import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormHelperText, Grid } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import Loading from '@common/Loading/Loading';

import {
  DynamicCrmFiltersSelector,
  DynamicCrmContactSelector,
  DynamicCrmLoadingSelector,
  DynamicCrmProcessingSelector,
  FilteredContactsSelector,
  resetFilteredContactsData,
} from '@store/slices/dynamicCrmContacts';
import {
  ThunkAddDynamicCrmContact,
  ThunkGetDynamicCrmFilters,
  ThunkGetDynamicCrmContact,
  ThunkGetFilteredContacts,
  ThunkEditDynamicCrmContact,
} from '@store/slices/dynamicCrmContacts/thunks';
import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import { DYNAMIC_CRM_FILTERS } from '@constants/routes';
import { validateRequired } from '@common/functions/validators';

import FilteredList from './components/FilteredList';
import Filters from './components/Filters';
import PsaIntegrationDropDown from './components/PsaIntegrationDropDown';

import { transformFilterFormValue, transformFilterPayload } from '../helpers';

import useStyles from './styles';

const DynamicFilterForm = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const { id: contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const filteredContacts = useSelector(FilteredContactsSelector);
  const dynamicCrmContact = useSelector(DynamicCrmContactSelector);
  const processing = useSelector(DynamicCrmProcessingSelector);
  const dynamicCrmFilters = useSelector(DynamicCrmFiltersSelector);
  const loading = useSelector(DynamicCrmLoadingSelector);

  useEffect(() => {
    if (contactId) {
      dispatch(ThunkGetDynamicCrmContact(contactId))
        .unwrap()
        .then(res => {
          dispatch(ThunkGetDynamicCrmFilters(res.crmId));
        });
    } else {
      dispatch(ThunkGetCrmSources());
    }
    return () => {
      dispatch(resetFilteredContactsData());
    };
  }, [dispatch, contactId]);

  const submit = values => {
    const payload = {
      crmId: values.crmId,
      name: values.name,
      filters: transformFilterPayload(values.filters),
    };

    if (contactId) {
      payload.id = contactId;
    }

    const currentThunk = contactId
      ? ThunkEditDynamicCrmContact
      : ThunkAddDynamicCrmContact;

    dispatch(currentThunk(payload))
      .unwrap()
      .then(() => {
        history.push(DYNAMIC_CRM_FILTERS);
        enqueueSnackbar(
          `Dynamic Filter  successfully ${contactId ? 'updated' : 'created'}`,
          {
            variant: 'success',
          },
        );
      });
  };

  const validate = values => ({
    crmId: validateRequired(values.crmId),
    name: validateRequired(values.name),
    isEmptyFilterList:
      values.filters.length === 0
        ? 'At least one filter must be added.'
        : undefined,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={
          contactId
            ? {
                ...dynamicCrmContact,
                filters: transformFilterFormValue(
                  dynamicCrmFilters,
                  dynamicCrmContact,
                ),
              }
            : { name: '', filters: [], test: [] }
        }
        mutators={arrayMutators}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values, errors, submitFailed }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={6}>
                <FieldWrapper
                  label="Filter Name"
                  labelSize={12}
                  contentSize={12}
                  size="small"
                  fullWidth
                  isRequired
                  classNameContainer={classes.nameField}
                  content={<Field name="name" id="name" render={Input} />}
                />
              </Grid>
              {!contactId && (
                <Grid item xs={6}>
                  <FieldWrapper
                    label="PSA Integration"
                    labelSize={12}
                    contentSize={12}
                    fullWidth
                    isRequired
                    classNameContainer={classes.crmIdField}
                    content={
                      <Field
                        id="crmId"
                        name="crmId"
                        component={PsaIntegrationDropDown}
                        size="small"
                        disabled={processing}
                        labelName="name"
                        valueName="id"
                      />
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} className={classes.filters}>
                <Filters />
                {errors.isEmptyFilterList && submitFailed && (
                  <FormHelperText className={classes.filtersError} error>
                    {errors.isEmptyFilterList}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} className={classes.buttonsWrapper}>
                <ReusableButton
                  label="Back"
                  onClick={() => {
                    history.push(DYNAMIC_CRM_FILTERS);
                  }}
                  disabled={processing}
                />
                <ReusableButton
                  label="Test"
                  onClick={() => {
                    dispatch(
                      ThunkGetFilteredContacts({
                        crmId: values.crmId,
                        filters: transformFilterPayload(values.filters),
                      }),
                    );
                  }}
                  disabled={processing || !values.filters.length}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                />
              </Grid>
            </Grid>
          </form>
        )}
      />
      {!!filteredContacts.length && <FilteredList />}
    </div>
  );
};

export default DynamicFilterForm;
