import React from 'react';
import { useSelector } from 'react-redux';

import { PricePlansSelector } from '@store/slices/billing';

import PricePlan from './PricePlan';

import useStyles from '../styles';

const PricePlansList = ({ form, values }) => {
  const classes = useStyles();
  const pricePlans = useSelector(PricePlansSelector);

  return (
    <div className={classes.pricePlans}>
      {pricePlans?.map(i => (
        <PricePlan
          key={i.id}
          {...i}
          selected={i.id === values.pricingPlanId}
          handlePlanSelected={id => {
            form.change('pricingPlanId', id);
          }}
        />
      ))}
    </div>
  );
};

export default PricePlansList;
