import createActionTypes from '@utils/createActionTypes';
import createAction from '@utils/createAction';

export const actionTypes = createActionTypes([
  'REGISTRATION',
  'LOGIN',
  'GET_FILES',
  'GET_FILES_SUCCESS',
  'GET_FILES_LOADING',
  'GET_USER_IMAGE',
  'GET_USER_IMAGE_SUCCESS',
  'SET_USER_IMAGE',
  'USERS_CREATE',
  'REMOVE_FILES',
  'DELETE_PROFILE',
  'SAVE_PROFILE_DATA',
  'REMOVE_COMPANY_FROM_USERS',
  'COMPANY_CREATE',
  'COMPANY_UPDATE',
  'COMPANY_DELETE',
  'USERS_FINISH_REGISTRATION',
  'CREATE_CHAT_RELATED_ENTITIES',
  'DELETE_CHAT_RELATED_ENTITIES',
  'CHANGE_THEME',
  'SAVE_LIST',
  'PATCH_LIST_ITEM',
  'ADD_OR_UPDATE_ITEM_IN_LIST',
  'NOTIFICATION_SAVE_DATA_VIEW_ID',
  'NOTIFICATION_SAVE_SETTING',
  'NOTIFICATION_RESET',
  'NOTIFICATION_SAVE_TICKET_SETTING',
  'NOTIFICATION_SAVE_EMAIL_SETTING',
  'NOTIFICATION_SAVE_SMS_SETTING',
  'NOTIFICATION_SAVE_WEBHOOK_SETTING',
  'NOTIFICATION_SAVE_SLACK_SETTING',
  'LETTER_DETAILS_SET_VIEW_TYPE',
  'REMOVE_ITEM_FROM_LIST',
  'SAVE_PRICE_PLAN',
  'SET_CRM_TYPE',
  'NOTIFICATION_SAVE_TEAMS_SETTING',
  'NOTIFICATION_SAVE_FTP_SETTING',
  'NOTIFICATION_SAVE_VOICE_SETTING',
  'SIGNAL_R_SUBSCRIPTION',
  'SET_SELECTED_NETWORKS',
  'RESET_SELECTED_NETWORKS',
  'SET_SELECTED_EXPRESSION_NAMES',
  'DELETE_SELECTED_EXPRESSION_NAME',
  'RESET_SELECTED_EXPRESSION_NAMES',
  'DASHBOARD_SAVE_CHANGED_LAYOUTS',
  'DASHBOARD_SET_TILES',
  'DASHBOARD_ADD_TILE',
  'DASHBOARD_REMOVE_TILE',
  'DASHBOARD_SET_OPTIONS',
  'DASHBOARD_SET_DATAVIEW_OPTIONS',
  'DASHBOARD_ADD_ITEM',
  'SMSLIST_SET_TENANT_OPTIONS',
  'SMSLIST_SET_INFO',
  'CRM_USERS_SET_CRM_OPTIONS',
  'CHATS_SET_LIST',
  'CHATS_SET_CURRENT',
  'CHATS_SET_MESSAGES',
  'USER_SET_CURRENT_USER',
  'SET_PUSHER_CONFIG',
  'CHAT_SET_NOTIFICATIONS',
]);

export const actions = {
  registration: createAction(actionTypes.REGISTRATION, 'values', 'redirectTo'),
  login: createAction(actionTypes.LOGIN, 'values', 'redirectTo'),
  createUser: createAction(
    actionTypes.USERS_CREATE,
    'values',
    'handleRedirect',
  ),
  deleteProfile: createAction(actionTypes.DELETE_PROFILE, 'values'),
  saveProfile: createAction(actionTypes.SAVE_PROFILE_DATA, 'payload'),
  createCompany: createAction(
    actionTypes.COMPANY_CREATE,
    'values',
    'handleRedirect',
  ),
  updateCompany: createAction(
    actionTypes.COMPANY_UPDATE,
    'values',
    'handleRedirect',
  ),
  deleteCompany: createAction(
    actionTypes.COMPANY_DELETE,
    'values',
    'handleRedirect',
  ),
  finishRegistration: createAction(
    actionTypes.USERS_FINISH_REGISTRATION,
    'values',
    'onSuccess',
    'onFailure',
  ),
  createChatRelations: createAction(
    actionTypes.CREATE_CHAT_RELATED_ENTITIES,
    'values',
    'handleRedirect',
  ),
  changeTheme: createAction(actionTypes.CHANGE_THEME, 'theme'),
  saveList: createAction(actionTypes.SAVE_LIST, 'payload'),
  updateItemInList: createAction(
    actionTypes.ADD_OR_UPDATE_ITEM_IN_LIST,
    'payload',
  ),
  removeItemFromList: createAction(
    actionTypes.REMOVE_ITEM_FROM_LIST,
    'payload',
  ),
  patchListItem: createAction(actionTypes.PATCH_LIST_ITEM, 'payload'),
  saveDataViewId: createAction(
    actionTypes.NOTIFICATION_SAVE_DATA_VIEW_ID,
    'payload',
  ),
  saveSetting: createAction(actionTypes.NOTIFICATION_SAVE_SETTING, 'payload'),

  saveTicketSetting: createAction(
    actionTypes.NOTIFICATION_SAVE_TICKET_SETTING,
    'payload',
  ),
  saveEmailSetting: createAction(
    actionTypes.NOTIFICATION_SAVE_EMAIL_SETTING,
    'payload',
  ),
  saveSmsSetting: createAction(
    actionTypes.NOTIFICATION_SAVE_SMS_SETTING,
    'payload',
  ),
  saveWebHookSetting: createAction(
    actionTypes.NOTIFICATION_SAVE_WEBHOOK_SETTING,
    'payload',
  ),

  saveSlackSetting: createAction(
    actionTypes.NOTIFICATION_SAVE_SLACK_SETTING,
    'payload',
  ),

  saveTeamsSetting: createAction(
    actionTypes.NOTIFICATION_SAVE_TEAMS_SETTING,
    'payload',
  ),

  saveFtpSetting: createAction(
    actionTypes.NOTIFICATION_SAVE_FTP_SETTING,
    'payload',
  ),

  saveVoiceSetting: createAction(
    actionTypes.NOTIFICATION_SAVE_VOICE_SETTING,
    'payload',
  ),

  resetSetting: createAction(actionTypes.NOTIFICATION_RESET, 'payload'),

  setViewType: createAction(
    actionTypes.LETTER_DETAILS_SET_VIEW_TYPE,
    'payload',
  ),

  setPricePlan: createAction(actionTypes.SAVE_PRICE_PLAN, 'payload'),

  setCrmType: createAction(actionTypes.SET_CRM_TYPE, 'payload'),

  setSelectedNetworks: createAction(
    actionTypes.SET_SELECTED_NETWORKS,
    'payload',
  ),

  resetSelectedNetworks: createAction(actionTypes.RESET_SELECTED_NETWORKS),

  setSelectedExpressionNames: createAction(
    actionTypes.SET_SELECTED_EXPRESSION_NAMES,
    'payload',
  ),

  deleteSelectedExpressionName: createAction(
    actionTypes.DELETE_SELECTED_EXPRESSION_NAME,
    'payload',
  ),

  resetSelectedExpressionNames: createAction(
    actionTypes.RESET_SELECTED_EXPRESSION_NAMES,
    'payload',
  ),

  signalRSubscription: createAction(
    actionTypes.SIGNAL_R_SUBSCRIPTION,
    'payload',
  ),

  dashboard_saveChangedLayouts: createAction(
    actionTypes.DASHBOARD_SAVE_CHANGED_LAYOUTS,
    'payload',
  ),

  dashboard_setTiles: createAction(actionTypes.DASHBOARD_SET_TILES, 'payload'),

  dashboard_addTile: createAction(actionTypes.DASHBOARD_ADD_TILE, 'payload'),

  dashboard_setOptions: createAction(
    actionTypes.DASHBOARD_SET_OPTIONS,
    'payload',
  ),

  dashboard_removeTile: createAction(
    actionTypes.DASHBOARD_REMOVE_TILE,
    'payload',
  ),

  dashboard_setDatViewOptions: createAction(
    actionTypes.DASHBOARD_SET_DATAVIEW_OPTIONS,
    'payload',
  ),

  dashboard_addItem: createAction(actionTypes.DASHBOARD_ADD_ITEM, 'payload'),

  smsList_setInfo: createAction(actionTypes.SMSLIST_SET_INFO, 'payload'),

  crmUsers_setOptions: createAction(
    actionTypes.CRM_USERS_SET_CRM_OPTIONS,
    'payload',
  ),

  chats_setList: createAction(actionTypes.CHATS_SET_LIST, 'payload'),
  chats_setCurrent: createAction(actionTypes.CHATS_SET_CURRENT, 'payload'),
  chats_setMessages: createAction(actionTypes.CHATS_SET_MESSAGES, 'payload'),
  chat_setNotifications: createAction(
    actionTypes.CHAT_SET_NOTIFICATIONS,
    'payload',
  ),

  setCurrentUser: createAction(actionTypes.USER_SET_CURRENT_USER, 'payload'),
  setPusherConfig: createAction(actionTypes.SET_PUSHER_CONFIG, 'payload'),
};
