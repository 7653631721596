import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '4rem 16px 0 11px',
    width: 'auto',
    maxHeight: '85%',
    overflow: 'scroll',
    textAlign: 'right',
  },
  createButton: {
    marginBottom: '16px',
  },
}));

export default useStyles;
