import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormState, useForm } from 'react-final-form';
import { FormHelperText, Menu, MenuItem } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';
import iconPlus from '@assets/icons/plusWhite.svg';

import { DynamicCrmFiltersSelector } from '@store/slices/dynamicCrmContacts';
import { filterFieldsType } from '@components/Messaging/DynamicFilters/constants';

import useStyles from '../styles';

const FilterDropDown = () => {
  const classes = useStyles();

  const dynamicCrmFilters = useSelector(DynamicCrmFiltersSelector);

  const { values } = useFormState({ subscription: { values: true } });
  const { change } = useForm();
  const [anchorEl, setAnchorEl] = useState(null);

  const filterList = useMemo(
    () =>
      dynamicCrmFilters
        .filter(({ id }) => !values.filters.some(filter => filter.id === id))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [dynamicCrmFilters, values.filters],
  );

  return (
    <div className={classes.FilterDropDownWrapper}>
      <ReusableButton
        onClick={event => setAnchorEl(event.currentTarget)}
        viewType="orange"
        disabled={!filterList.length}
      >
        <>
          <img src={iconPlus} alt="iconPlus" />
          Add Filter
        </>
        {!values.crmId && (
          <FormHelperText className={classes.helperInfoText}>
            Select a PSA Integration before choosing Filter criteria
          </FormHelperText>
        )}
      </ReusableButton>
      {!!filterList.length && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {filterList.map(filter => (
            <MenuItem
              onClick={() => {
                const filterValue = { ...filter };
                filterValue.value = '';

                if (filterValue.filterDataType === filterFieldsType.Boolean) {
                  filterValue.value = 'true';
                }

                if (
                  filterValue.filterDataType === filterFieldsType.NumberArray
                ) {
                  filterValue.value = [];
                }

                change(
                  'filters',
                  [...values.filters, filterValue].sort((a, b) => a.id - b.id),
                );

                // close menu if list is empty
                if (filterList.length === 1) {
                  setAnchorEl(null);
                }
              }}
              key={filter.key}
            >
              {filter.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default memo(FilterDropDown);
